import React, { useState } from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"
import Presentation from "../../Presentation"
import Valeurs from "../Valeurs"

const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
  padding: 4rem 0.5rem 1rem;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 3rem 2rem 1rem;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    padding: 4rem 2rem 1rem;
  }
`

const List = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const Item = styled.li`
    flex: 1 1 33%;
    list-style: none;
    text-transform: uppercase;
    color: ${props => props.theme.primary};
    padding: 0;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      background: ${props => props.theme.primary};
      height: 2px;
      width: 60%;
      transform: ${props =>
        props.isactive
          ? "scaleX(1) translateX(-50%)"
          : "scaleX(0) translateX(-50%)"};
      transform-origin: left center 0px;
      transition: ${props =>
        props.isactive
          ? "transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68) 0s"
          : "transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s"};
    }
  }

  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 0;
    padding: 0 1.5rem;
    flex: 0 0 auto;
      &:hover::after {
        transform: scaleX(1) translateX(-50%);
        transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68) 0s;
      }
  }
`

const QuiSommesNous = ({ reseau, histoire, data }) => {
  const [content, setContent] = useState("notre-histoire")

  const handleClick = value => {
    setContent(value)
  }

  return (
    <Wrapper>
      {/* <List>
        <Item
          onClick={() => handleClick("notre-histoire")}
          isactive={content === "notre-histoire"}
          className="button-menu"
        >
          {data.histoire.titre.text}
        </Item>
        <Item
          onClick={() => handleClick("nos-valeurs")}
          isactive={content === "nos-valeurs"}
          className="button-menu"
        >
          {data.valeurs.titre.text}
        </Item>
        <Item
          onClick={() => handleClick("lequipe")}
          isactive={content === "lequipe"}
          className="button-menu"
        >
          {data.equipe.titre.text}
        </Item>
      </List> */}
      {content === "notre-histoire" && (
        <Presentation
          histoire={data.histoire.image}
          title={data.histoire.titre.text}
          text={data.histoire.contenu.html}
          padding="0"
        />
      )}
      {/* {content === "nos-valeurs" && (
        <Valeurs
          title={data.valeurs.titre.text}
          text={data.valeurs.contenu}
          zindex="-1"
          items={data.valeurs.items}
          data={data.valeurs}
        />
      )}
      {content === "lequipe" && (
        <Presentation
          histoire={histoire}
          title={data.equipe.titre.text}
          text={data.equipe.contenu}
        />
      )} */}
    </Wrapper>
  )
}

export default QuiSommesNous
