import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"

import Container from "../../elements/Container"
import Title from "../../elements/Title"
import Input from "../../elements/Input"
import Textarea from "../../elements/Textarea"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"
import { validator } from "./validationRules"

const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
  padding: 2rem 0;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const WrapperForm = styled.div`
  background-color: ${props => props.theme.white};
  border-bottom: 3px solid ${props => props.theme.secondary};
  box-shadow: 0px 10px 40px 5px rgba(171, 171, 171, 0.38);
  padding: 3rem 1rem 0 1rem;

  @media (min-width: ${globalVariables.minTablet}) {
    padding: 5rem 5rem 0rem 5rem;
  }
`

const FormStyled = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  position: relative;
  @media (min-width: ${globalVariables.medDesktop}) {
    padding-top: 2rem;
  }
`

const Spacer = styled.div`
  flex: ${props => (props.flex ? props.flex : "1 1 100%")};
  padding: 1rem 1rem;
  position: relative;
  button span {
    color: ${props => props.theme.primary};
  }
  text-align: ${props => (props.textAlign ? "center" : null)};
`
const ValidationMessage = styled.span`
  position: absolute;
  bottom: -11px;
  font-size: 90%;
  color: ${props => props.theme.error};
  opacity: ${props => (props.isInvalid ? "1" : "0")};
`

const SuccessMessage = styled.span`
  position: absolute;
  bottom: -11px;
  font-size: 90%;
  color: ${props => props.theme.secondary};
  opacity: ${props => (props.isSent ? "1" : "0")};
`

const Contact = () => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    isSent: false,
    validation: validator.valid(),
  }
  const [formData, setFormData] = useState({
    ...initialState,
  })

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const { firstName, lastName, email, phoneNumber, message } = formData

    const validation = validator.validate(formData)
    setFormData({
      ...formData,
      validation,
    })
    setIsFormSubmitted(true)

    if (validation.isValid) {
      axios
        .post(
          "https://pa3k5wjlqh.execute-api.eu-west-3.amazonaws.com/api/contact",
          {
            firstName,
            lastName,
            phoneNumber,
            email,
            message,
          }
        )
        .then(result => {
          setFormData({ ...initialState, isSent: true })
        })
    }
  }

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    message,
    validation,
  } = formData
  // if the form has been submitted at least once, then check validity every time we render
  // otherwise just use what's in state
  const formValidation =
    isFormSubmitted && !formData.isSent
      ? validator.validate(formData)
      : validation

  return (
    <Wrapper>
      <Container>
        <WrapperForm>
          <Title as="h2">Contactez-nous</Title>
          <FormStyled onSubmit={handleSubmit}>
            <Spacer flex="1 1 50%">
              <Input
                type="text"
                placeholder="Nom"
                value={lastName}
                handleChange={e => handleInputChange(e)}
                name="lastName"
                required
                fullWidth
                id="lastName"
              />
              <ValidationMessage isInvalid={formValidation.lastName.isInvalid}>
                {formValidation.lastName.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Input
                type="text"
                placeholder="Prénom"
                value={firstName}
                handleChange={e => handleInputChange(e)}
                name="firstName"
                required
                fullWidth
                id="firstName"
              />
              <ValidationMessage isInvalid={formValidation.firstName.isInvalid}>
                {formValidation.firstName.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                handleChange={e => handleInputChange(e)}
                name="email"
                required
                fullWidth
                id="email"
              />
              <ValidationMessage isInvalid={formValidation.email.isInvalid}>
                {formValidation.email.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Input
                type="tel"
                placeholder="Téléphone"
                value={phoneNumber}
                handleChange={e => handleInputChange(e)}
                name="phoneNumber"
                fullWidth
                id="phoneNumber"
              />
              <ValidationMessage
                isInvalid={formValidation.phoneNumber.isInvalid}
              >
                {formValidation.phoneNumber.message}
              </ValidationMessage>
            </Spacer>
            <Spacer>
              <Textarea
                type="text"
                placeholder="Votre message"
                value={message}
                handleChange={e => handleInputChange(e)}
                name="message"
                required
                fullWidth
                id="message"
              />
              <ValidationMessage isInvalid={formValidation.message.isInvalid}>
                {formValidation.message.message}
              </ValidationMessage>
            </Spacer>
            <SuccessMessage isSent={formData.isSent}>
              Merci, votre message a bien été envoyé !
            </SuccessMessage>
            <Spacer textAlign="center">
              <Button title="Envoyer" type="submit" colors="secondary" />
            </Spacer>
          </FormStyled>
        </WrapperForm>
      </Container>
    </Wrapper>
  )
}

export default Contact
