const globalVariables = {
  borderRadius: "3px",
  borderRadiusButton: "0px",
  borderWidth: "2px",
  navbarHeight: "56px",
  navbarHeightBigScreen: "80px",
  footerHeight: "95px",
  maxMobile: "767px",
  minTablet: "768px",
  maxTablet: "991px",
  minDesktop: "992px",
  medDesktop: "1441px",
  bigDesktop: "1920px",
}

export default globalVariables
