// Modal Component
// You need to specify a trigger component (like a button) in order to open
// the modal

import React, { useState } from "react"
import Modal from "styled-react-modal"
import styled from "styled-components"
import { X } from "styled-icons/feather/X"

const StyledModal = Modal.styled`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.white};
  position: relative;
`
const Circle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: ${props => props.theme.white};
  border-radius: 50%;
  width: 45px;
  height: 45px;
`

const CloseIcon = styled(X)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${props => props.theme.primary};
`

const ModalComponent = ({ triggerComponent, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const [bodyScrollPos, setBodyScrollPos] = useState(0)

  const toggleModal = e => {
    setIsOpen(!isOpen)
  }

  const lockBodyScrolling = () => {
    // Get scroll position to be able to restore it in onCloseModal
    const bodyScrollPos =
      document.body.scrollTop || document.documentElement.scrollTop || 0
    setBodyScrollPos(bodyScrollPos)
    const appWrapper = document.getElementById("___gatsby")
    appWrapper.style.position = "fixed"
    appWrapper.style.top = `-${bodyScrollPos}px`
    appWrapper.style.width = `100%`
  }

  const restoreBodyScrolling = () => {
    const appWrapper = document.getElementById("___gatsby")
    appWrapper.style.removeProperty("position")
    appWrapper.style.removeProperty("top")
    appWrapper.style.removeProperty("width")
    window.scrollTo(0, document.body.scrollHeight)

    // document.documentElement.scrollTop = document.body.scrollTop = bodyScrollPos
  }

  const afterOpen = () => {
    setTimeout(() => {
      lockBodyScrolling()
      setOpacity(1)
    })
  }

  const beforeClose = () => {
    return new Promise(resolve => {
      restoreBodyScrolling()
      setOpacity(0)
      setTimeout(resolve, 200)
    })
  }

  const triggerElement = Component => {
    return <Component handleClick={toggleModal} />
  }

  return (
    <div>
      {triggerElement(triggerComponent)}
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        allowScroll={false}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        {children}
        <Circle>
          <CloseIcon onClick={toggleModal} />
        </Circle>
      </StyledModal>
    </div>
  )
}

export default ModalComponent
