import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import { getInputStyles, getInnerStyle } from "./style"

const InputWrapper = styled.div`
  ${getInputStyles};
`

// The actual input element
const InputElement = styled.input`
  flex-grow: 1;
  background: none;
  border: none;
  outline: none;
  transition: box-shadow 200ms ease-in-out;
  transition-delay: 50ms;
  ${getInnerStyle};
`

// Icon Base
const IconContent = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
`

const IconLeft = styled(IconContent)`
  margin-right: 8px;
`

const IconRight = styled(IconContent)`
  margin-left: 8px;
`

const Input = ({
  defaultValue,
  placeholder,
  type,
  value,
  name,
  autofocus,
  iconLeft,
  iconRight,
  disabled,
  fullWidth,
  handleChange,
  onKeyDown,
  onKeyPress,
  id,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const inputRef = useRef()

  useEffect(() => {
    if (inputRef && autofocus) {
      inputRef.current.focus()
    }
  }, [])

  const onFocus = e => {
    setIsFocus(true)
  }

  const onBlur = e => {
    setIsFocus(false)
  }

  const onMouseEnter = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  const onMouseDown = e => {
    e.preventDefault()
    inputRef.current.focus()
  }

  return (
    <InputWrapper
      disabled={disabled}
      fullWidth={fullWidth}
      isFocus={isFocus}
      isHover={isHover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
    >
      {iconLeft ? <IconLeft>{iconLeft}</IconLeft> : null}
      <InputElement
        defaultValue={defaultValue}
        ref={inputRef}
        placeholder={placeholder}
        disabled={disabled}
        onMouseDown={e => {
          // If selected directly don't refocus.
          e.stopPropagation()
        }}
        type={type}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        id={id}
      />
      {iconRight ? <IconRight>{iconRight}</IconRight> : null}
    </InputWrapper>
  )
}

export default Input
