import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import styled from "styled-components"

import getButtonStyled from "./style"

const ButtonStyle = styled.button`
  ${getButtonStyled}
`
const LinkStyle = styled(Link)`
  ${getButtonStyled}
`

const Button = ({
  type,
  as,
  colors,
  title,
  icon,
  disabled,
  iconPosition,
  onClick,
  loader,
  isMaterial,
  isLoading,
  className,
  nomargin,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = []

  // isLoading prop checking
  if (isLoading) {
    addAllClasses.push("is-loading")
  }

  // isMaterial prop checking
  if (isMaterial) {
    addAllClasses.push("is-material")
  }

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  // Checking button loading state
  const buttonIcon =
    isLoading !== false ? (
      <Fragment> {loader}</Fragment>
    ) : (
      icon && <span className="btn-icon">{icon}</span>
    )

  // set icon position
  const position = iconPosition || "right"

  return as === "link" ? (
    <LinkStyle
      type={type}
      colors={colors}
      className={addAllClasses.join(" ")}
      icon={icon}
      disabled={disabled}
      icon-position={position}
      onClick={onClick}
      nomargin={nomargin}
      {...props}
    >
      {position === "left" && buttonIcon}
      {title && <span className="btn-text">{title}</span>}
      {position === "right" && buttonIcon}
    </LinkStyle>
  ) : (
    <ButtonStyle
      type={type}
      colors={colors}
      className={addAllClasses.join(" ")}
      icon={icon}
      disabled={disabled}
      icon-position={position}
      onClick={onClick}
      nomargin={nomargin}
      {...props}
    >
      {position === "left" && buttonIcon}
      {title && <span className="btn-text">{title}</span>}
      {position === "right" && buttonIcon}
    </ButtonStyle>
  )
}

Button.propTypes = {
  /** ClassName of the button */
  className: PropTypes.string,

  /** Add icon */
  type: PropTypes.oneOf(["button", "submit", "reset"]),

  /** Add icon */
  icon: PropTypes.object,

  /** Add loader */
  loader: PropTypes.object,

  /** Add Material effect */
  isMaterial: PropTypes.bool,

  /** Button Loading state */
  isLoading: PropTypes.bool,

  /** Button Loading state */
  loaderColor: PropTypes.string,

  /** If true button will be disabled */
  disabled: PropTypes.bool,

  /** Adjust your icon and loader position [if you use loader] */
  iconPosition: PropTypes.oneOf(["left", "right"]),

  /** primary || secondary || warning || error  change text and border color.
   *  And primaryWithBg || secondaryWithBg || warningWithBg || errorWithBg change text, border and background color */
  colors: PropTypes.oneOf([
    "primary",
    "secondary",
    "ternary",
    "error",
    "primaryWithBg",
    "secondaryWithBg",
    "errorWithBg",
    "link",
  ]),

  /**
   * Gets called when the user clicks on the button
   */
  onClick: PropTypes.func,
}

Button.defaultProps = {
  disabled: false,
  isMaterial: false,
  isLoading: false,
  type: "button",
}

export default Button
