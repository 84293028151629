import { css } from "styled-components"
import theme from "../../theme"

export const getInputStyles = props => {
  let cursor = "text"
  // Use box-shadow with inset instead of border to avoid animation jerk.
  let borderWidth = `1px`
  let width = ""
  let borderColor = theme.greyLight

  /**
   * Component state modifiers
   */

  if (props.isHover) {
    borderColor = theme.grey
  }

  if (props.isFocus) {
    borderWidth = "2px"
    borderColor = theme.primary
  }

  if (props.disabled) {
    cursor = "not-allowed"
  }

  if (props.fullWidth) {
    width = "100%"
  }

  return css`
    background: "transparent";
    border-bottom: 2px solid ${props => props.theme.primary};
    cursor: ${cursor};
    /* box-shadow: inset 0 0 0 ${borderWidth} ${borderColor}; */
    box-sizing: border-box;
    display: flex;
    flex: 1 0 auto;
    width: ${width};
    height: 40px;
    max-width: 100%;
    padding: 0 8px;
    align-items: center;
    line-height: 1.42857;
    word-wrap: break-word;
    /* font-size: 14px; */
    outline: none;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition-delay: 50ms;
  `
}

/**
 * Get the styles for the input inner component
 */
export const getInnerStyle = props => {
  return css`
    color: inherit;

    &::placeholder {
      color: ${props => props.theme.grey};
    }
  `
}
