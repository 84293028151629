import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { RichText } from "prismic-reactjs"

import globalVariables from "../../globalVariables"
import Title from "../../elements/Title"
import ValeursItem from "./Item"
import Dots from "../../elements/DotsCarousel"

const Container = styled.div`
  padding: 0 25px;
  @media (min-width: ${globalVariables.minTablet}) {
    max-width: 1170px;
    margin: auto;
  }
`

const WrapperContent = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 0 0;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 80px 0 0;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    padding: 100px 0 0;
  }
`

const WrapperText = styled.div`
  margin-top: 50px;
  margin-bottom: 10px;
  max-width: 600px;

  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 40px;
  }

  @media (min-width: ${globalVariables.medDesktop}) {
    max-width: 900px;
    margin-bottom: 60px;
  }
`

const Valeurs = ({ title, text, items }) => {
  const settings = {
    customPaging: function(i) {
      return <Dots />
    },
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Container>
      <WrapperContent>
        <Title as="h2">{title}</Title>
        <WrapperText>{RichText.render(text)}</WrapperText>
      </WrapperContent>
      <Slider {...settings}>
        {items.map((item, index) => (
          <ValeursItem
            title={item.titre.text}
            text={item.contenu.html}
            image={item.image_reassurance.url}
            key={index}
          />
        ))}
      </Slider>
    </Container>
  )
}

export default Valeurs
