const theme = {
  primary: "#4c6582",
  primaryLight: "#546c87",
  primaryDark: "#3e5570",
  secondary: "#cb9d81",
  secondaryLight: "rgb(220, 202, 190)",
  beige: "#eeece1",
  white: "#fff",
  white80: "rgba(245, 245, 245, 0.8)",
  black: "#000",
  grey: "rgb(82, 82, 82)",
  greyLight: "rgb(239, 239, 239)",
  greyLighter: "rgb(249, 249, 249)",
  greyDarker: "rgb(223, 223, 223)",
  error: "#dc3545",
  bodyColor: "#525252",
  headerColor: "#4c6582"
};

export default theme;
