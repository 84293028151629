import React from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"

import globalVariables from "../../globalVariables"

const Item = styled.div`
  flex: 1 1 33%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }
`

const WrapperImage = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 40px;
  }
`

const Title = styled.h3``

const ValeursItem = ({ title, text, image }) => {
  return (
    <Item>
      <WrapperImage>
        <img src={image} alt="icone de reassurance" />
      </WrapperImage>
      <Title as="h3">{title}</Title>
      {RichText.render(text)}
    </Item>
  )
}

export default ValeursItem
