import React from "react"
import styled, { css } from "styled-components"

const getHeaderStyle = props => {
  return css`
    position: relative;
    color: ${props => (props.color ? props.theme[props.color] : null)};
    text-align: ${props => (props.position === "left" ? "left" : "center")};
    &::after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: ${props => (props.position === "left" ? "0" : "50%")};
      transform: ${props =>
        props.position === "left" ? "translateX(0)" : "translateX(-50%)"};
      height: 4px;
      width: 44px;
      background: ${props =>
        props.color ? props.theme[props.color] : props.theme.primary};
    }
  `
}
const H1 = styled.h1``
const H2 = styled.h2`
  ${getHeaderStyle}
`
const H3 = styled.h3`
  ${getHeaderStyle}
`
const H4 = styled.h4`
  ${getHeaderStyle}
`
const H5 = styled.h5`
  ${getHeaderStyle}
`
const H6 = styled.h6`
  ${getHeaderStyle}
`

const Title = ({ as, position, color, children }) => {
  const getHeader = () => {
    switch (as) {
      case "h1":
        return (
          <H1 position={position} color={color}>
            {children}
          </H1>
        )
      case "h2":
        return (
          <H2 position={position} color={color}>
            {children}
          </H2>
        )
      case "h3":
        return (
          <H3 position={position} color={color}>
            {children}
          </H3>
        )
      case "h4":
        return (
          <H4 position={position} color={color}>
            {children}
          </H4>
        )
      case "h5":
        return (
          <H5 position={position} color={color}>
            {children}
          </H5>
        )
      case "h6":
        return (
          <H6 position={position} color={color}>
            {children}
          </H6>
        )
      default:
        return null
    }
  }

  return getHeader()
}

export default Title
