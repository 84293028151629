import React from "react"
import styled from "styled-components"

import Presentation from "../../Presentation"

const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
  padding: 2rem 0;
`

const MentionsLegales = ({ data }) => {
  return (
    <Wrapper>
      <Presentation
        title={data.titre.text}
        text={data.contenu.html}
        padding="0"
      />
    </Wrapper>
  )
}

export default MentionsLegales
