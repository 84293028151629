import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"

import globalVariables from "../globalVariables"
import { DeviceContext } from "../elements/DeviceProvider"

export const ItemAnchor = styled.li`
  list-style: none;
  padding: 0 1.5rem;
  margin-bottom: 25px;
  cursor: pointer;
  a,
  button {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.beige};
    transition: all 0.2s ease-in-out;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      background: ${props => props.theme.beige};
      height: 2px;
      width: 60%;
      transform: ${props =>
        props.isactive
          ? "scaleX(1) translateX(-50%)"
          : "scaleX(0) translateX(-50%)"};
      transform-origin: left center 0px;
      transition: ${props =>
        props.isactive
          ? "transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68) 0s"
          : "transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s"};
    }
  }

  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 0;
    a:hover,
    button:hover {
      &::after {
        transform: scaleX(1) translateX(-50%);
        transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68) 0s;
      }
    }
  }
`

const Item = styled.li`
  list-style: none;
  padding: 0 1.5rem;
  margin-bottom: 25px;
  cursor: pointer;
  position: relative;
  a,
  button {
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.beige};
    transition: all 0.2s ease-in-out;
  }

  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 0;
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 15px;
      width: 2px;
      background: ${props => props.theme.secondary};
    }
    a:hover,
    button:hover {
      color: ${props => props.theme.secondary};
    }
  }
`

const InternalLink = styled(AnchorLink)`
  font-size: 18px;
  min-height: 30px;
  display: block;
`

const ExternalLink = styled(Link)`
  font-size: 16px;
`

const MenuItem = ({ item, active, handleClick, isWrapperLogoHidden }) => {
  const getOffset = windowWidth => {
    if (windowWidth < 768) {
      return 130
    } else if (windowWidth < 1441) {
      return isWrapperLogoHidden ? 56 : 176
    } else {
      return isWrapperLogoHidden ? 80 : 320
    }
  }
  return item.anchor ? (
    <ItemAnchor isactive={active} onClick={handleClick}>
      <DeviceContext.Consumer>
        {deviceValues => {
          return deviceValues.width ? (
            <InternalLink
              href={"#" + item.anchor.text}
              className={"button-menu"}
              offset={() => getOffset(deviceValues.windowWidth)}
            >
              {item.title.text}
            </InternalLink>
          ) : null
        }}
      </DeviceContext.Consumer>
    </ItemAnchor>
  ) : (
    <Item>
      <ExternalLink
        to={item.url}
        activeClassName="active"
        className="button-menu"
      >
        {item.title}
      </ExternalLink>
    </Item>
  )
}

export default MenuItem
