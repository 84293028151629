import React from "react"
import styled from "styled-components"

import ModalComponent from "../elements/Modal"
import globalVariables from "../globalVariables"
import QuiSommesNous from "./QuiSommesNous"
import MentionsLegales from "./MentionsLegales"
import ProtectionDonnees from "./ProtectionDonnees"
import RevueDePresse from "./RevueDePresse"
import Contact from "./Contact"

const FooterStyled = styled.footer`
  background-color: ${props => props.theme.primary};
`
const Container = styled.div`
  padding-left: 25px;
  padding-right: 25px;
`

const List = styled.div`
  height: auto;
  padding: 2rem 0;
  color: ${props => props.theme.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 0;
    flex-direction: row;
    height: ${globalVariables.footerHeight};
    & > div:not(:last-child) > span::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 3px;
      background-color: ${props => props.theme.white};
    }
  }
`
const Item = styled.span`
  margin-bottom: 1rem;
  list-style: none;
  color: ${props => props.theme.white};
  text-transform: uppercase;
  padding: 0 40px;
  position: relative;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
  display: block;
  @media (min-width: ${globalVariables.minDesktop}) {
    margin: 0;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    font-size: 18px;
  }
`

const Footer = ({ histoire, modal }) => {
  return (
    <FooterStyled>
      <Container width="1400px">
        <List>
          <ModalComponent
            triggerComponent={({ handleClick }) => (
              <Item onClick={handleClick} className="button-footer">
                Qui sommes-nous
              </Item>
            )}
          >
            <QuiSommesNous histoire={histoire} data={modal} />
          </ModalComponent>
          <ModalComponent
            triggerComponent={({ handleClick }) => (
              <Item onClick={handleClick} className="button-footer">
                Presse
              </Item>
            )}
          >
            <RevueDePresse data={modal.presse} />
          </ModalComponent>
          <ModalComponent
            triggerComponent={({ handleClick }) => (
              <Item onClick={handleClick} className="button-footer">
                Contactez-nous
              </Item>
            )}
          >
            <Contact />
          </ModalComponent>
          <ModalComponent
            triggerComponent={({ handleClick }) => (
              <Item onClick={handleClick} className="button-footer">
                Mentions légales
              </Item>
            )}
          >
            <MentionsLegales data={modal.mentions} />
          </ModalComponent>
          <ModalComponent
            triggerComponent={({ handleClick }) => (
              <Item onClick={handleClick} className="button-footer">
                Protection des données
              </Item>
            )}
          >
            <ProtectionDonnees data={modal.protection} />
          </ModalComponent>
        </List>
      </Container>
    </FooterStyled>
  )
}

export default Footer
