import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Parser from "html-react-parser"

import globalVariables from "../globalVariables"
// import Container from "../elements/Container"
import Title from "../elements/Title"
import Button from "../elements/Button"

const Container = styled.div`
  padding: 0 25px;
  @media (min-width: ${globalVariables.minTablet}) {
    max-width: 1170px;
    margin: auto;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${props => (props.padding ? props.padding : "50px 0")};
  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : "80px 0 0")};
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex-direction: row;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    padding: ${props => (props.padding ? props.padding : "100px 0 0")};
  }
`

const Item = styled.div`
  flex: 0 0 100%;
  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 0 2rem;
    flex: 1 1 50%;
  }
`
const WrapperImage = styled.div`
  z-index: -1;

  @media (max-width: ${globalVariables.maxTablet}) {
    width: 250px;
    margin-bottom: 2rem;
  }
`

const WrapperText = styled.div`
  margin-top: 4rem;
  margin-bottom: 3rem;
`

const Presentation = ({
  histoire,
  title,
  text,
  buttonText,
  buttonUrl,
  padding,
}) => {
  return (
    <Container>
      <Wrapper padding={padding}>
        {histoire && (
          <Item>
            <WrapperImage>
              <Img fluid={histoire} />
            </WrapperImage>
          </Item>
        )}

        <Item>
          <Title as="h2" position="left">
            {title}
          </Title>
          <WrapperText>{Parser(text)}</WrapperText>
          {buttonText && buttonUrl && (
            <Button
              title={buttonText}
              as="link"
              to={buttonUrl}
              colors="secondaryWithBg"
              nomargin="true"
            />
          )}
        </Item>
      </Wrapper>
    </Container>
  )
}

export default Presentation
