import React, {
  useState,
  useEffect,
  useLayoutEffect,
  createContext,
} from "react"

/**
 * `createContext` contient 2 propriétés :
 * `Provider` et `Consumer`. Nous les rendons accessibles
 * via la constante `DeviceContext` et on initialise une
 * propriété par défaut "width" qui sera une chaîne vide.
 * On exporte ce contexte afin qu'il soit exploitable par
 * d'autres composants par la suite via le `Consumer`
 */
export const DeviceContext = createContext({
  windowWidth: "",
})

/**
 * la classe DeviceProvider fera office de... Provider (!)
 * en englobant son enfant direct
 * dans le composant éponyme. De cette façon, ses values
 * seront accessibles de manière globale via le `Consumer`
 */
const DeviceProvider = ({ children }) => {
  let width

  if (typeof window !== "undefined") {
    width = window.innerWidth
  }

  const [windowWidth, setWindowWidth] = useState(width)

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowSizeChange)

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  // double check for ssr
  // because of the trouble with hydratation
  // const [isClient, setIsClient] = useState(typeof window !== "undefined")

  // useEffect(() => {
  //   setIsClient(true)
  // }, [])

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth)
  }

  const isMobile = windowWidth <= 767

  return (
    // isClient && (
    /**
     * la propriété value est très importante ici, elle rend
     * le contenu du state disponible aux `Consumers` de l'application
     */
    <DeviceContext.Provider value={{ windowWidth, isMobile, width }}>
      {children}
    </DeviceContext.Provider>
    // )
  )
}

export default DeviceProvider
