import React, { useState, useEffect } from "react"

import MenuItem from "../../Navbar/MenuItem"
import { menuData } from "../../../data/index"

const ScrollSpyMenu = ({ handleClick, ids, isWrapperLogoHidden, menu }) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const items = ids
      .map(id => {
        const element = document.getElementById(id)
        if (element) {
          return {
            id: id,
            inView: isInView(element),
            element,
          }
        } else {
          return
        }
      })
      .filter(item => item)
    setItems(items)

    // const firstTrueItem = items.find(item => !!item && item.inView)

    // if (!firstTrueItem) {
    //   return // dont update state
    // } else {
    //   const update = items.map(item => {
    //     return { ...item, inView: item === firstTrueItem }
    //   })
    //   setItems(update)
    // }
  }

  const isInView = element => {
    if (!element) {
      return false
    }
    const rect = element.getBoundingClientRect()
    return rect.top <= window.innerHeight / 2 && rect.bottom >= 0
  }

  const isItemActive = anchor => {
    if (items.length > 0) {
      return items.filter(item => item.id === anchor && item.inView).length > 0
    }
    return false
  }

  return (
    <React.Fragment>
      {menu.map((item, index) => (
        <MenuItem
          key={"item" + index}
          item={item}
          active={isItemActive(item.anchor)}
          handleClick={handleClick}
          isWrapperLogoHidden={isWrapperLogoHidden}
        />
      ))}
    </React.Fragment>
  )
}

export default ScrollSpyMenu
