import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

// import Logo from "../../images/logo.png"
// import MiniLogo from "../../images/mini-logo.png"
import globalVariables from "../globalVariables"
// import { menuData2 } from "../../data/index"
// import MenuItem from "./MenuItem"
import ScrollSpyMenu from "../elements/ScrollSpyMenu"

const Header = styled.header`
  @media (min-width: ${globalVariables.minTablet}) {
    width: 100%;
    z-index: 1;
    transition: all 0.1s ease-in-out;
  }
`

const WrapperLogo = styled.div`
  background-color: ${props => props.theme.primary};
  text-align: center;
  height: 130px;
  width: 100%;
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 20;
  @media (min-width: ${globalVariables.minTablet}) {
    position: relative;
    height: ${props => (props.isWrapperLogoHidden ? "0" : "120px")};
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    height: ${props => (props.isWrapperLogoHidden ? "0" : "240px")};
  }
`

const WrapperLogoStyled = styled.div`
  width: 130px;
  margin: auto;
  @media (min-width: ${globalVariables.minTablet}) {
    opacity: ${props => (props.isWrapperLogoHidden ? "0" : "1")};
    width: ${props => (props.isWrapperLogoHidden ? "0" : "120px")};
    transition: all 0.2s ease-in-out;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    width: ${props => (props.isWrapperLogoHidden ? "0" : "240px")};
  }
`

const LogoStyled = styled(Img)`
  height: 100%;
  width: 100%;
`

const LinkStyled = styled(Link)`
  width: 100%;
`

const WrapperMiniLogo = styled.div`
  @media (max-width: ${globalVariables.maxMobile}) {
    width: 40px;
    margin: 1.5rem;
  }

  @media (min-width: ${globalVariables.minTablet}) {
    /* height: ${props => (props.isWrapperLogoHidden ? "35px" : "0")}; */
    width: ${props => (props.isWrapperLogoHidden ? "35px" : "0")};
    transition: all 0.2s ease-in-out;
    margin-left: 0;
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    /* height: ${props => (props.isWrapperLogoHidden ? "45px" : "0")}; */
    width: ${props => (props.isWrapperLogoHidden ? "45px" : "0")};
  }
`

const MiniLogoStyled = styled(Img)`
  height: 100%;
  width: 100%;
  @media (min-width: ${globalVariables.minTablet}) {
  }
`

const WrapperBurger = styled.button`
  cursor: pointer;
  z-index: 30;
  width: 40px;
  display: block;
  background: transparent;
  border: none;
  outline: none !important;
  padding: 0;
  position: fixed;
  top: 1rem;
  right: 1rem;
  @media (min-width: ${globalVariables.minTablet}) {
    display: none;
  }
`

const BurgerBar = styled.span`
  display: block;
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.white};
  border-radius: 2px;
  margin: 10px 0;
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  /* border: 1px solid white; */
  &:nth-child(1) {
    transform: ${props =>
      props.isBurgerActive
        ? "rotate(-405deg) translate(-10px,7px)"
        : "#rotate(0deg) translate(0,0)"};
  }
  &:nth-child(2) {
    background-color: ${props =>
      props.isBurgerActive ? "transparent" : props.theme.white};
  }
  &:nth-child(3) {
    transform: ${props =>
      props.isBurgerActive
        ? "rotate(405deg) translate(-10px,-7px)"
        : "#rotate(0deg) translate(0,0)"};
  }
`

const Nav = styled.nav`
  background-color: ${props => props.theme.primary};
  border-top: 1px solid rgba(245, 245, 245, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 20;
  @media (min-width: ${globalVariables.minTablet}) {
    min-height: ${globalVariables.navbarHeight};
    position: ${props => (props.isWrapperLogoHidden ? "fixed" : "relative")};
    top: ${props => (props.isWrapperLogoHidden ? "0" : "auto")};
    width: 100%;
    /* align-items: flex-start; */
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    /* align-items: center; */
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    min-height: ${globalVariables.navbarHeightBigScreen};
  }
`

const Spacer = styled.div`
  min-height: 130px;
  width: 100%;
  display: block;
  transition: all 0.2s ease-in-out;
  @media (min-width: ${globalVariables.minTablet}) {
    min-height: ${globalVariables.navbarHeight};
    width: 100%;
    display: ${props => (props.isWrapperLogoHidden ? "block" : "none")};
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    min-height: ${globalVariables.navbarHeightBigScreen};
    display: ${props => (props.isWrapperLogoHidden ? "block" : "none")};
  }
`

const Menu = styled.ul`
  @media (max-width: ${globalVariables.maxMobile}) {
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    padding: 50px 40px 30px;
    transform: ${props =>
      props.isBurgerActive ? "translateX(0)" : "translateX(100%)"};
    opacity: ${props => (props.isBurgerActive ? "1" : "0")};
    z-index: ${props => (props.isBurgerActive ? "1" : "0")};
    width: 300px;
    height: 100vh;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    background-color: ${props => props.theme.primary};
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    flex-direction: column;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-left: 0;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

// const SideMenu = styled.ul`
//   margin: 0;
//   @media (min-width: ${globalVariables.minTablet}) {
//     position: absolute;
//     top: 50%;
//     right: 1rem;
//     transform: translateY(-50%);
//     display: flex;
//     flex-direction: row;
//   }
// `

const Navbar = ({ logo, miniLogo, menu }) => {
  const [isBurgerActive, setToggleMenu] = useState(false)
  const [isWrapperLogoHidden, setIsWrapperLogoHidden] = useState(false)

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    const menuTopPosition = document.getElementById("menuTopPosition").offsetTop

    if (currentScrollY > menuTopPosition) {
      setIsWrapperLogoHidden(true)
    } else {
      setIsWrapperLogoHidden(false)
    }
  }

  const handleClick = () => {
    setToggleMenu(!isBurgerActive)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Header isWrapperLogoHidden={isWrapperLogoHidden}>
      <WrapperBurger onClick={handleClick}>
        <BurgerBar isBurgerActive={isBurgerActive} />
        <BurgerBar isBurgerActive={isBurgerActive} />
        <BurgerBar isBurgerActive={isBurgerActive} />
      </WrapperBurger>
      <WrapperLogo isWrapperLogoHidden={isWrapperLogoHidden}>
        <LinkStyled to="/">
          <WrapperLogoStyled isWrapperLogoHidden={isWrapperLogoHidden}>
            <LogoStyled fluid={logo} />
          </WrapperLogoStyled>
        </LinkStyled>
      </WrapperLogo>
      <Nav id="menuTopPosition" isWrapperLogoHidden={isWrapperLogoHidden}>
        <Menu isBurgerActive={isBurgerActive}>
          <WrapperMiniLogo isWrapperLogoHidden={isWrapperLogoHidden}>
            <LinkStyled to="/">
              <MiniLogoStyled fluid={miniLogo} />
            </LinkStyled>
          </WrapperMiniLogo>
          <ScrollSpyMenu
            ids={["le-collectif", "inscription", "les-membres"]}
            handleClick={handleClick}
            isWrapperLogoHidden={isWrapperLogoHidden}
            menu={menu}
          />

          {/* <SideMenu>
            {menuData2.map((item, index) => (
              <MenuItem key={"item" + index} item={item} />
            ))}
          </SideMenu> */}
        </Menu>
      </Nav>
      <Spacer isWrapperLogoHidden={isWrapperLogoHidden} />
    </Header>
  )
}

export default Navbar
