import styled from "styled-components"

const Dots = styled.a`
  display: block;
  height: 100%;
  /* height: 3px; */
  width: 30px;
  border-top: 3px solid ${props => props.theme.secondaryLight};
  /* background: ${props => props.theme.secondaryLight}; */
`

export default Dots
