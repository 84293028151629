/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import styled from "styled-components"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"

import theme from "./theme"
import Navbar from "./Navbar"
import Footer from "./Footer"
import "./layout.css"
import DeviceProvider from "./elements/DeviceProvider"

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
`

const Layout = ({
  logo,
  miniLogo,
  reseau,
  histoire,
  modal,
  menu,
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider backgroundComponent={FadingBackground}>
        <DeviceProvider>
          <Navbar logo={logo} miniLogo={miniLogo} menu={menu} />
          {children}
          <Footer reseau={reseau} histoire={histoire} modal={modal} />
        </DeviceProvider>
      </ModalProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
