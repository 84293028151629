import { css } from "styled-components"

export const getTextareaStyles = props => {
  let cursor = "text"
  // Use box-shadow with inset instead of border to avoid animation jerk.
  let width = ""

  /**
   * Component state modifiers
   */

  if (props.disabled) {
    cursor = "not-allowed"
  }

  if (props.fullWidth) {
    width = "100%"
  }

  return css`
    background: "transparent";
    border-bottom: 2px solid ${props => props.theme.primary};
    cursor: ${cursor};
    box-sizing: border-box;
    display: flex;
    flex: 1 0 auto;
    width: ${width};
    max-width: 100%;
    align-items: center;
    line-height: 1.42857;
    word-wrap: break-word;
    outline: none;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition-delay: 50ms;
  `
}

/**
 * Get the styles for the input inner component
 */
export const getInnerStyle = props => {
  return css`
    color: inherit;
  `
}
