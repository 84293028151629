import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import globalVariables from "../../globalVariables"
import Title from "../../elements/Title"

const Container = styled.div`
  height: 100%;
  overflow: scroll;
`

const WrapperContainer = styled.div`
  padding: ${props => (props.padding ? props.padding : "50px 0")};
  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : "80px 0 0")};
  }

  @media (min-width: ${globalVariables.medDesktop}) {
    padding: ${props => (props.padding ? props.padding : "100px 0 0")};
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4rem;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const Item = styled.a`
  text-decoration: none;
  display: block;
  flex: 0 1 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 1 33.33%;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 0 1 25%;
  }
`

const TitleItem = styled.h3`
  font-size: 20px;
  margin-bottom: 5px;
`

const WrapperImage = styled.div`
  width: 100%;
  margin-bottom: 15px;
  
  .gatsby-image-wrapper {
    width: 100%;
    /* padding: 1rem;
    outline: 2px solid ${props => props.theme.greyLight};
    outline-offset: 15px; */
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 40px;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 260px;

  }
`

const Date = styled.p``

const RevueDePresse = ({ data }) => {
  return (
    <Container>
      <WrapperContainer>
        <Title as="h2">{data.titre.text}</Title>
        <Wrapper>
          {data.items.map((item, index) => {
            return (
              <Item
                key={index}
                href={
                  item.lien &&
                  item.lien.url !== "https://www.collectif-engages.fr/"
                    ? item.lien.url
                    : null
                }
                target={
                  item.lien &&
                  item.lien.url !== "https://www.collectif-engages.fr/"
                    ? "_blank"
                    : null
                }
                rel={
                  item.lien &&
                  item.lien.url !== "https://www.collectif-engages.fr/"
                    ? "noopener noreferrer"
                    : null
                }
                as={
                  item.lien &&
                  item.lien.url !== "https://www.collectif-engages.fr/"
                    ? "a"
                    : "div"
                }
              >
                <WrapperImage>
                  <Img fluid={item.image.fluid} alt="icone de reassurance" />
                </WrapperImage>
                <TitleItem>{item.media.text}</TitleItem>
                <Date>{item.date.text}</Date>
              </Item>
            )
          })}
        </Wrapper>
      </WrapperContainer>
    </Container>
  )
}

export default RevueDePresse
